import { createSelector } from 'reselect';
import { MultiAppsSurvey } from 'src/reducers/surveys-multi-apps';

import { State } from '../reducers';

const withSurveyId = (_: State, surveyId: string) => surveyId;

export const getAllSurveysMultiApps = (state: State): MultiAppsSurvey[] => {
  let res: MultiAppsSurvey[] = []
  if (state.surveysMultiApps.surveysActive && state.surveysMultiApps.surveysNotActive) {
    res = [...state.surveysMultiApps.surveysActive, ...state.surveysMultiApps.surveysNotActive]
  }
  if (!state.surveysMultiApps.surveysActive && state.surveysMultiApps.surveysNotActive) {
    res = state.surveysMultiApps.surveysNotActive
  }
  if (state.surveysMultiApps.surveysActive && !state.surveysMultiApps.surveysNotActive) {
    res = state.surveysMultiApps.surveysActive
  }
  return res
}

export const getActiveSurveysMultiApps = (state: State): MultiAppsSurvey[] => (state.surveysMultiApps.surveysActive ? [...state.surveysMultiApps.surveysActive] : []);
export const getNotActiveSurveysMultiApps = (state: State): MultiAppsSurvey[] => (state.surveysMultiApps.surveysNotActive ? [...state.surveysMultiApps.surveysNotActive] : []);
export const getSurveysMultiAppsIsLoading = (state: State, isActive = true): boolean => (isActive ? state.surveysMultiApps.loadingActive : state.surveysMultiApps.loadingNotActive)
export const getSurveysMultiAppsIsBootstrapped = (state: State): boolean => state.surveysMultiApps.bootstrapped;

const getLoadingMultiAppsIds = (state: State): string[] => (state.surveysMultiApps.loadingIds ? [...state.surveysMultiApps.loadingIds] : []);
const getActivationMultiAppsId = (state: State): string => (state.surveysMultiApps.updatingSurveyActiveId ? state.surveysMultiApps.updatingSurveyActiveId : '');
const getStatsMultiApps = (state: State) => state.surveysMultiApps.surveyStatistics;

export const getSurveyV12ById = createSelector(
  [getAllSurveysMultiApps, withSurveyId],
  (surveys, surveyId) => surveys.find((item) => item.id === surveyId)
);

export const getSurveysV12WithInteractionTarget = createSelector(
  [getAllSurveysMultiApps],
  (surveys) => surveys.filter((item) => item.selfTargeting === false)
);

export const getLoadingSurveyV12ById = createSelector(
  [getLoadingMultiAppsIds, withSurveyId],
  (ids, surveyId) => ids.includes(surveyId)
);

export const getSurveyV12Stats = createSelector(
  [getStatsMultiApps, withSurveyId],
  (stats, surveyId) => {
    const statistics = stats[surveyId];
    return statistics ? statistics.stats || [] : [];
  }
);

export const getSurveyV12TextAnswers = createSelector(
  [getStatsMultiApps, withSurveyId],
  (stats, surveyId) => {
    const statistics = stats[surveyId];
    return statistics ? statistics.textAnswers || {} : {};
  }
);

export const getActivatingSurveyV12ById = createSelector(
  [getActivationMultiAppsId, withSurveyId],
  (id, surveyId) => id === surveyId
);
