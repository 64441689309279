import { Rest, API } from 'src/api';
import { isNetlifyProd } from 'src/utils/environment';
import { ReachResp, SLReachResp } from 'src/reducers/apps.types';

import { SdkVersion } from 'src/types/core';
import { BaseService } from './base-service';

export const DEFAULT_SL_REACH: SLReachResp = {
  app_id: '',
  start_date: '2022-12-18',
  end_date: '2023-01-18',
  total_mau: 164,
  skip_logic_percentage: 0.6098,
};

export const DEFAULT_REACH_BY_VERSION_RESP: ReachResp = {
  app_id: '',
  start_date: '2022-12-18',
  end_date: '2023-01-18',
  total_mau: 164,
  reach_percentage: 0.6098,
};

export class DruidService extends BaseService {
  static fetchSkipLogicReach(appId: string): Promise<SLReachResp> {
    if (!isNetlifyProd()) {
      return Promise.resolve({ ...DEFAULT_SL_REACH, app_id: appId } as SLReachResp);
    }
    return Rest.httpGet(API.DRUID.GET_SKIP_LOGIC_REACH(appId), {
      errorTitle: '[API] Error GET skip logic reach info',
    }).promise;
  }

  static fetchReachByVersion(appId: string, version: SdkVersion): Promise<ReachResp> {
    if (!isNetlifyProd()) {
      return Promise.resolve({ ...DEFAULT_REACH_BY_VERSION_RESP, app_id: appId } as ReachResp);
    }
    return Rest.httpGet(API.DRUID.GET_REACH_BY_VERSION(appId, version), {
      errorTitle: '[API] Error GET version logic reach info',
    }).promise;
  }

  static fetchMultipleReachByVersion(orgId: string, appIds: string[], version: SdkVersion): Promise<ReachResp[]> {
    if (!isNetlifyProd()) {
      return Promise.resolve(
        appIds.map(
          (appId) =>
            ({
              ...DEFAULT_REACH_BY_VERSION_RESP,
              app_id: appId,
              reach_percentage: DEFAULT_REACH_BY_VERSION_RESP.reach_percentage,
            }) as ReachResp,
        ),
      );
    }
    return Rest.httpPost(API.DRUID.GET_MULTIPLE_REACH_BY_VERSION(orgId, version), {
      body: {
        apps: appIds,
      },
      errorTitle: '[API] Error POST multiple version reach info',
    }).promise;
  }
}
