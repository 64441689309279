import { Time, timestampToSeconds } from 'src/utils/time';
import { FanSignalStatus } from 'src/reducers/insights';

import templates from 'src/utils/jsons/survey_v12_templates.json';

export const FanSignalTemplateName = {
  [FanSignalStatus.SHIFTED_TO_RISK]: `FanSignalsSurveyTemplate-${FanSignalStatus.SHIFTED_TO_RISK}`,
};

export type TemplateName = keyof typeof templates | keyof typeof FanSignalTemplateName;
export type Templates = Record<TemplateName, any>;

export { templates };

export function createSurveyTemplateFromFanSignals(startDate: number, endDate: number) {
  const fromDate = Time.create(startDate).format(Time.FORMAT.ll);
  const toDate = Time.create(endDate).format(Time.FORMAT.ll);
  return {
    interaction_data: [
      {
        active: false,
        start_time: new Date().toISOString(),
        view_period: 86400,
        multiple_responses: false,
        response_rate_cached_time: 0,
        response_count: 0,
        response_rate: 0,
        criteria: {
          _version: 7,
          $and: [
            { 'fs_state/LostFan': { $gte: timestampToSeconds(startDate) } },
            { 'fs_state/LostFan': { $lte: timestampToSeconds(endDate) } },
          ],
        },
      },
    ],
    display_response_rate: '',
    required: false,
    success_message: 'Thank you!',
    show_success_message: true,
    view_limit: null,
    name: "We're here to help!",
    title: `Fan Signal - Shifted to Risk Survey ${fromDate} - ${toDate}`,
    description: 'We saw you might not be enjoying your mobile experience and would like to assist.',
    self_targeting: true,
    end_time: null,
    resp_max: null,
    question_sets: [
      {
        id: '',
        logical_id: '5ec52b1e45523a7d26001101',
        order: 0,
        actions_attributes: [],
        questions: [
          {
            type: 'multiselect',
            value: 'Have you faced any of the following issues?',
            required: false,
            randomize: false,
            min_selections: '1',
            max_selections: null,
            min: 0,
            max: 10,
            min_label: '',
            max_label: '',
            multiline: false,
            other_choice: true,
            other_choice_text: 'Other',
            answer_choices: [
              {
                value: 'App crash',
                type: 'select_option',
              },
              {
                value: 'Product availability',
                type: 'select_option',
              },
              {
                value: 'Page load times',
                type: 'select_option',
              },
            ],
          },
        ],
      },
      {
        id: '',
        logical_id: '5ec52b1e45523a7d26001102',
        order: 1,
        actions_attributes: [],
        questions: [
          {
            type: 'singleline',
            value: 'What suggestions do you have to make this app better for you?',
            required: false,
            randomize: false,
            min_selections: '0',
            max_selections: null,
            min: 0,
            max: 10,
            min_label: '',
            max_label: '',
            multiline: false,
            other_choice: false,
            other_choice_text: 'Other',
          },
        ],
      },
    ],
  };
}
