import { omit } from 'ramda';
import { MultiAppsSurvey } from 'src/reducers/surveys-multi-apps';
import { IFreeFormQuestion, IMultiChoiceQuestion, IRangeQuestion, SurveyQuestionType } from '../types/core';
import camelCaseKeys from './camelcase-keys';
import { mockApiFetch, useMockApi } from './mock';
import { atriumCall, report } from './util';

function castToQuestionTypeInterface(
  data: Record<string, any>
): IMultiChoiceQuestion | IFreeFormQuestion | IRangeQuestion {
  const type = data.type as SurveyQuestionType;

  switch (type) {
    case 'multichoice':
    case 'multiselect':
      return data as IMultiChoiceQuestion;
    case 'nps':
    case 'range':
      return data as IRangeQuestion;
    case 'singleline':
      return data as IFreeFormQuestion;
    default:
      throw new Error(`Unexpected question type "${type}"`);
  }
}

export interface ISurveysMultiAppsApi {
  fetchSurveys: (orgId: string) => Promise<MultiAppsSurvey[]>;
  deactiveSurvey(orgId: string, surveyId: string): Promise<void>;
  activateSurvey(orgId: string, surveyId: string): Promise<void>;
  deleteSurvey(orgId: string, surveyId: string): Promise<void>;
  updateEmailSummaryAppIds(userId: string, summaryEmailAppIds: string[]): Promise<void>;
}

const api: ISurveysMultiAppsApi = {
  fetchSurveys,
  deactiveSurvey,
  activateSurvey,
  deleteSurvey,
  updateEmailSummaryAppIds,
};

export default api;

async function fetchSurveys(orgId: string, page: number = 1): Promise<MultiAppsSurvey[]> {
  let jsonArray: MultiAppsSurvey[];
  if (useMockApi) {
    jsonArray = await mockApiFetch(`organization/${orgId}/surveys`);
  } else {
    jsonArray = await atriumCall(`/organization/${orgId}/surveys?page=${page}`, {
      method: 'GET',
      error: (ex: Error) => {
        report('[API] Error Fetching Surveys for', orgId, ex);
      },
    });
  }

  if (!Array.isArray(jsonArray)) {
    return [];
  }

  return jsonArray.map((jsonSurvey: any) => {
    const survey = camelCaseKeys(omit(['questions'], jsonSurvey)) as MultiAppsSurvey;

    survey.questionSets = jsonSurvey.questions.map(castToQuestionTypeInterface);
    return survey;
  });
}

async function deactiveSurvey(orgId: string, surveyId: string): Promise<void> {
  if (useMockApi) {
    await mockApiFetch(`/organization/${orgId}/surveys/${surveyId}/deactivate`, { method: 'PUT' });
    return;
  }

  await atriumCall(`/organization/${orgId}/surveys/${surveyId}/deactivate?api_version=12`, {
    method: 'PUT',
    error: (ex: Error) => {
      report('[API] Error deactivating survey', orgId, surveyId, ex);
    },
  });
}

async function activateSurvey(orgId: string, surveyId: string): Promise<void> {
  if (useMockApi) {
    await mockApiFetch(`/organization/${orgId}/surveys/${surveyId}/activate`, { method: 'PUT' });
    return;
  }

  await atriumCall(`/organization/${orgId}/surveys/${surveyId}/activate?api_version=12`, {
    method: 'PUT',
    error: (ex: Error) => {
      report('[API] Error deactivating survey', orgId, surveyId, ex);
    },
  });
}

async function deleteSurvey(orgId: string, surveyId: string): Promise<void> {
  if (useMockApi) {
    await mockApiFetch(`/organization/${orgId}/surveys/${surveyId}`, { method: 'DELETE' });
    return;
  }

  await atriumCall(`/organization/${orgId}/surveys/${surveyId}`, {
    method: 'DELETE',
    error: (ex: Error) => {
      report('[API] Error deleting survey', orgId, surveyId, ex);
    },
  });
}

// Update the list of appIds to which the user is subscribed to survey summary emails.
async function updateEmailSummaryAppIds(userId: string, summaryEmailAppIds: string[]): Promise<void> {
  await atriumCall('/user', {
    method: 'PUT',
    body: JSON.stringify({ user_id: userId, survey_report_sub_ids: summaryEmailAppIds }),
    error: (e: Error) => report('[API] Error Updating User', e),
    skipCheckForError: true,
  });
}
