import {
  append,
  assoc,
  assocPath,
  compose,
  dissoc,
  find,
  findIndex,
  prop,
  propEq,
  remove,
  sortBy,
  toLower,
  update,
  path,
  uniqBy,
} from 'ramda';
import * as appActions from 'src/actions/apps';
import { AppDataActions } from 'src/actions/app-data/app-data.action-types';
import * as attributeChoicesActions from 'src/actions/attribute_choices';
import * as targetingActions from 'src/actions/targeting';
import { RECEIVE_QUICK_RESPONSES } from 'src/actions/quick_responses';
import { FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS, RECEIVE_APPS_FOR_ORG } from 'src/actions/organizations';
import * as sessionActions from 'src/actions/session';
import { EventsActions } from 'src/actions/events';
import * as integrationActions from 'src/actions/integrations';
import * as translationActions from 'src/actions/translations';
import { FanSignalActions } from 'src/actions/fan-signals';
import { SdkVersion } from 'src/types/core';
import { CHANGE_APP_STORE_ID_PENDING, CHANGE_APP_STORE_ID_FAILURE } from '../actions/app_store_id';
import {
  ADD_APP_MEMBER_SUCCESS,
  REMOVE_APP_MEMBER_SUCCESS,
  UPDATE_APP_MEMBER_SUCCESS,
  ADD_APP_MEMBER_FAILURE,
} from '../actions/app_members';

const defaultState = {};

// TODO: Normalize the naming of params at the Atrium level to use camelcase
export default (state = defaultState, action = {}) => {
  switch (action.type) {
    case sessionActions.UPDATE_CURRENT_APP_PENDING:
      return assoc('pendingUpdateCurrentApp', true, state);
    case sessionActions.UPDATE_CURRENT_APP_SUCCESS:
      return assoc('pendingUpdateCurrentApp', false, state);
    case sessionActions.UPDATE_CURRENT_APP_FAILED:
      return assoc('pendingUpdateCurrentApp', false, state);
    case appActions.REQUEST_APP:
      return assocPath([action.meta.appId, 'loading'], true, state);
    case appActions.RECEIVED_APP: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          allEvents: {
            items: [],
          },
          fullyFetched: true,
          loading: false,
          lastUpdated: Date.now(),
          appMembers: action.payload.app_members || [], // {role: 'admin'|'collaborator'}
          defaultLocale: action.payload.default_locale,
          displayName: action.payload.display_name,
          hideBranding: action.payload.hideBranding,
          iconUrl: action.payload.icon_url,
          id: action.payload.id || action.meta.appId,
          integratedFeatures: action.payload.integratedFeatures || action.payload.integrated_features,
          interactionReminderEmails: action.payload.interaction_reminder_emails,
          intlAppStorePref: action.payload.international_app_store_data,
          live: action.payload.live,
          localePolicy: action.payload.locale_policy,
          organization: action.payload.organization,
          platform: action.payload.platform,
          messageCenterId: action.payload.message_center_id,
          client_support: action.payload.client_support,
          storeAppId: action.payload.store_app_id,
          supportDisplayEmail: action.payload.support_display_email,
          supportDisplayName: action.payload.support_display_name,
          supportImageUrl: action.payload.support_image_url,
          title: action.payload.title,
          websdkDebugFlag: action.payload.websdk_debug_flag,
          wentLiveOn: action.payload.went_live_on,
          styles: action.payload.styles,
          selected_cid_keys: action.payload.selected_cid_keys,
        },
      };
    }

    case AppDataActions.FETCH_APP_DATA_SUCCESS:
    case AppDataActions.SAVE_APP_DATA_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          ...action.payload,
        },
      };
    }

    case FanSignalActions.FETCH_LOVE_SCORE_SUCCESS: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          love_score: action.payload.love_score,
        },
      };
    }

    case appActions.SAVE_APP_STYLES_SUCCESS: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          styles: action.payload.styles,
        },
      };
    }

    case ADD_APP_MEMBER_SUCCESS: {
      const newAppMembers = append(action.payload, state[action.meta.appId].appMembers);
      return assocPath([action.meta.appId, 'appMembers'], newAppMembers, state);
    }
    case REMOVE_APP_MEMBER_SUCCESS: {
      if (state[action.meta.appId] && state[action.meta.appId].appMembers) {
        const index = findIndex(propEq('id', action.meta.appMemberId))(state[action.meta.appId].appMembers);
        const newAppMembers = remove(index, 1, state[action.meta.appId].appMembers);
        return assocPath([action.meta.appId, 'appMembers'], newAppMembers, state);
      }
      return state;
    }
    case UPDATE_APP_MEMBER_SUCCESS: {
      const index = findIndex(propEq('id', action.meta.appMemberId))(state[action.meta.appId].appMembers);
      const newAppMembers = update(index, action.payload, state[action.meta.appId].appMembers);
      return assocPath([action.meta.appId, 'appMembers'], newAppMembers, state);
    }
    case ADD_APP_MEMBER_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          addAppMemberFailureErrorMessage: action.payload,
        },
      };
    }
    case RECEIVE_APPS_FOR_ORG: {
      return {
        ...state,
        ...action.payload.reduce((apps, app) => {
          apps[app.id] = {
            ...(apps[app.id] || {}),
            ...app,
            ...state[app.id],
            iconUrl: app.icon_url,
            organization: action.meta.orgId,
          };
          return apps;
        }, {}),
      };
    }
    case EventsActions.REQUEST_EVENTS: {
      const aggregate = state[action.meta.appId].events
        ? {
            ...state[action.meta.appId].events.aggregate,
            indicies: action.meta.indicies || state[action.meta.appId].events.aggregate.indicies,
          }
        : {
            indicies: action.meta.indicies || [],
            selected: [],
          };

      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          events: {
            ...state[action.meta.appId].events,
            aggregate: { ...aggregate },
            error: false,
            loading: true,
          },
        },
      };
    }
    case EventsActions.RECEIVE_EVENTS: {
      const mergedItems = uniqBy(
        (item) => item.label,
        [...state[action.meta.appId].allEvents.items, ...action.payload.items],
      );
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          allEvents: {
            bootstrapped: false,
            loading: false,
            items: mergedItems,
          },
          events: {
            ...state[action.meta.appId].events,
            aggregate: {
              ...state[action.meta.appId].events.aggregate,
              ...action.payload,
            },
            error: false,
            loading: false,
          },
        },
      };
    }
    case EventsActions.FETCH_ALL_EVENTS_PENDING: {
      const { appId } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          allEvents: {
            ...state[appId].allEvents,
            bootstrapped: false,
            loading: true,
          },
        },
      };
    }
    case EventsActions.FETCH_ALL_EVENTS_SUCCESS: {
      const { appId, events } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          allEvents: {
            bootstrapped: true,
            loading: false,
            items: events.items,
          },
        },
      };
    }
    case EventsActions.FETCH_ALL_EVENTS_ERROR: {
      const { appId } = action.payload;
      return {
        ...state,
        [appId]: {
          ...state[appId],
          allEvents: {
            ...state[appId].allEvents,
            bootstrapped: false,
            loading: false,
          },
        },
      };
    }
    case EventsActions.ERRORED_EVENTS:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          events: {
            ...state[action.meta.appId].events,
            error: true,
            loading: false,
          },
        },
      };
    case EventsActions.RECEIVE_CREATE_EVENT:
      // eslint-disable-next-line
      const events = state[action.meta.appId].events
        ? state[action.meta.appId].events
        : { aggregate: { items: [], total: 0 } };
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          allEvents: {
            ...state[action.meta.appId].allEvents,
            bootstrapped: false,
            items: [action.payload, ...state[action.meta.appId].allEvents.items],
          },
          events: {
            ...events,
            aggregate: {
              ...events.aggregate,
              items: events.aggregate.items.reduce(
                (newItems, event) => {
                  event.label !== action.payload.label
                    ? newItems.push({ ...event })
                    : (newItems[0] = { ...newItems[0], ...event });
                  return newItems;
                },
                [{ ...action.payload }],
              ),
              total: events.aggregate.total + 1,
            },
          },
        },
      };
    case EventsActions.RECEIVE_UPDATE_EVENT: {
      const { label, archive: archived } = action.payload;
      const allItemsPath = [action.meta.appId, 'allEvents', 'items'];
      const allItemIndex = findIndex(propEq('label', label), path(allItemsPath, state));

      const itemsPath = [action.meta.appId, 'events', 'aggregate', 'items'];
      const itemIndex = findIndex(propEq('label', label), path(itemsPath, state));

      // archive or unarchive the affected event, as appropriate
      const updatedEventsItems = update(
        itemIndex,
        {
          ...path(itemsPath, state)[itemIndex],
          archived,
        },
        path(itemsPath, state),
      );

      const updatedAllEventsItems = update(
        allItemIndex,
        {
          ...path(allItemsPath, state)[allItemIndex],
          archived,
        },
        path(allItemsPath, state),
      );

      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          allEvents: {
            ...state[action.meta.appId].allEvents,
            bootstrapped: false,
            items: updatedAllEventsItems,
          },
          events: {
            ...state[action.meta.appId].events,
            aggregate: {
              ...state[action.meta.appId].events.aggregate,
              items: updatedEventsItems,
            },
          },
        },
      };
    }
    case EventsActions.RECEIVE_DELETE_EVENT:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          allEvents: {
            ...state[action.meta.appId].allEvents,
            bootstrapped: false,
            items: state[action.meta.appId].allEvents.items.filter((item) => item.label !== action.meta.label),
          },
          events: {
            ...state[action.meta.appId].events,
            aggregate: {
              ...state[action.meta.appId].events.aggregate,
              items: state[action.meta.appId].events.aggregate.items.filter(
                (event) => event.label !== action.meta.label,
              ),
              total: state[action.meta.appId].events.aggregate.total - 1,
            },
          },
        },
      };
    case EventsActions.REQUEST_EVENT_TIMESERIES:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          events: {
            ...state[action.meta.appId].events,
            aggregate: {
              ...state[action.meta.appId].events?.aggregate,
              selected: [...action.payload],
            },
            timeseries: {
              ...state[action.meta.appId].events?.timeseries,
              error: false,
              loading: true,
            },
          },
        },
      };
    case EventsActions.RECEIVE_EVENT_TIMESERIES: {
      const scrubbedLabels = action.payload.data.map((timeseries) => ({
        ...timeseries,
        label: timeseries.label.replace(/local#app#/gi, ''),
      }));

      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          events: {
            ...state[action.meta.appId].events,
            timeseries: {
              ...state[action.meta.appId].events?.timeseries,
              data: scrubbedLabels,
              error: false,
              loading: false,
            },
          },
        },
      };
    }
    case EventsActions.ERRORED_EVENT_TIMESERIES:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          events: {
            ...state[action.meta.appId].events,
            timeseries: {
              ...state[action.meta.appId].timeseries,
              error: true,
              loading: false,
            },
          },
        },
      };
    case integrationActions.FETCH_INTEGRATIONS_PENDING:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrationsError: false,
          integrationsLoading: true,
          integrationsSaving: false,
          integrationsSavingError: false,
        },
      };
    case integrationActions.FETCH_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrations: action.payload,
          integrationsError: false,
          integrationsLoading: false,
          integrationsSaving: false,
          integrationsSavingError: false,
        },
      };
    case integrationActions.FETCH_INTEGRATIONS_FAILURE:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrationsError: true,
          integrationsLoading: false,
          integrationsSaving: false,
          integrationsSavingError: false,
        },
      };
    case integrationActions.CREATE_INTEGRATION_PENDING:
    case integrationActions.UPDATE_INTEGRATION_PENDING:
    case integrationActions.REMOVE_INTEGRATION_PENDING:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrationsError: false,
          integrationsLoading: false,
          integrationsSaving: true,
          integrationsSavingError: false,
        },
      };
    case integrationActions.CREATE_INTEGRATION_SUCCESS: {
      const integrations = assoc(
        action.meta.id,
        action.payload[action.meta.id],
        dissoc(action.meta.id, state[action.meta.appId].integrations || {}),
      );
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrations,
          integrationsError: false,
          integrationsLoading: false,
          integrationsSaving: false,
          integrationsSavingError: false,
          integrationsSavingErrorMessage: '',
        },
      };
    }
    case integrationActions.UPDATE_INTEGRATION_SUCCESS: {
      const integrations = assoc(
        action.meta.id,
        action.payload[action.meta.id],
        dissoc(action.meta.id, state[action.meta.appId].integrations || {}),
      );
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrations,
          integrationsError: false,
          integrationsLoading: false,
          integrationsSaving: false,
          integrationsSavingError: false,
          integrationsSavingErrorMessage: '',
        },
      };
    }
    case integrationActions.REMOVE_INTEGRATION_SUCCESS: {
      const integrations = dissoc(action.meta.id, state[action.meta.appId].integrations || {});
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrations,
          integrationsError: false,
          integrationsLoading: false,
          integrationsSaving: false,
          integrationsSavingError: false,
        },
      };
    }
    case integrationActions.CREATE_INTEGRATION_FAILURE:
    case integrationActions.UPDATE_INTEGRATION_FAILURE:
    case integrationActions.REMOVE_INTEGRATION_FAILURE: {
      const currentApp = state[action.meta.appId] || {};
      const integrationId = action.meta.id || action.meta.data.type;
      const currentIntegration = currentApp.integrations ? currentApp.integrations[action.meta.id] : {};
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          integrationsError: false,
          integrationsLoading: false,
          integrationsSaving: false,
          integrationsSavingError: true,
          integrationsSavingErrorMessage: action.payload,
          integrations: {
            ...currentApp.integrations,
            [integrationId]: {
              ...currentIntegration,
              errorMessage: action.payload,
            },
          },
        },
      };
    }
    case translationActions.FETCH_APP_TRANSLATIONS_PENDING: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          translationsError: false,
          translationsErrorMessage: '',
          translationsLoading: true,
        },
      };
    }
    case translationActions.FETCH_APP_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          translations: action.payload,
          translationsError: false,
          translationsErrorMessage: '',
          translationsLoading: false,
        },
      };
    }
    case translationActions.FETCH_APP_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          translationsError: true,
          translationsErrorMessage: action.payload,
          translationsLoading: false,
        },
      };
    }
    case translationActions.UPDATE_APP_TRANSLATIONS_PENDING: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          translationsSaving: true,
          translationsSavingError: false,
          translationsSavingErrorMessage: '',
        },
      };
    }
    case translationActions.UPDATE_APP_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          translationsStatus: 'File received. Please check the Download Center icon for upload status.',
          translationsSaving: false,
          translationsSavingError: false,
          translationsSavingErrorMessage: '',
        },
      };
    }
    case translationActions.UPDATE_APP_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          translationsStatus: '',
          translationsSaving: false,
          translationsSavingError: true,
          translationsSavingErrorMessage: action.payload.error,
        },
      };
    }
    case attributeChoicesActions.FETCH_ATTRIBUTE_CHOICES_PENDING: {
      const attributeChoices = state[action.meta.appId].attributeChoices
        ? { ...state[action.meta.appId].attributeChoices }
        : {};
      const attributeChoicesBootstrapped = state[action.meta.appId].attributeChoicesBootstrapped
        ? { ...state[action.meta.appId].attributeChoicesBootstrapped }
        : {};
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          attributeChoices: {
            ...attributeChoices,
          },
          attributeChoicesBootstrapped: {
            ...attributeChoicesBootstrapped,
            [action.meta.type]: false,
          },
          attributeChoicesError: false,
          attributeChoicesLoading: true,
        },
      };
    }
    case attributeChoicesActions.FETCH_ATTRIBUTE_CHOICES_SUCCESS: {
      const attributeChoices = state[action.meta.appId].attributeChoices
        ? { ...state[action.meta.appId].attributeChoices }
        : {};
      const attributeChoicesBootstrapped = state[action.meta.appId].attributeChoicesBootstrapped
        ? { ...state[action.meta.appId].attributeChoicesBootstrapped }
        : {};
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          attributeChoices: {
            ...attributeChoices,
            [action.meta.type]: action.payload,
          },
          attributeChoicesBootstrapped: {
            ...attributeChoicesBootstrapped,
            [action.meta.type]: true,
          },
          attributeChoicesError: false,
          attributeChoicesLoading: false,
        },
      };
    }
    case attributeChoicesActions.FETCH_ATTRIBUTE_CHOICES_FAILURE:
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          attributeChoicesError: true,
          attributeChoicesLoading: false,
        },
      };
    case RECEIVE_QUICK_RESPONSES:
      return assocPath(
        [action.meta.appId, 'quickResponses'],
        sortBy(compose(toLower, prop('title')), action.payload || []),
        state,
      );
    case CHANGE_APP_STORE_ID_PENDING:
      return assocPath([action.meta.appId, 'storeAppId'], action.payload.storeId, state);
    case CHANGE_APP_STORE_ID_FAILURE:
      return assocPath([action.meta.appId, 'storeAppId'], '', state);
    case appActions.FETCH_APPSTORE_ICON_PENDING: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          appStoreIconLoading: true,
          appStoreIconError: false,
        },
      };
    }
    case targetingActions.FETCH_TARGETING_PENDING: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          targetingError: false,
          targetingLoading: true,
        },
      };
    }
    case targetingActions.FETCH_TARGETING_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          targeting: action.payload,
          targetingError: false,
          targetingLoading: false,
        },
      };
    }
    case targetingActions.FETCH_TARGETING_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          targetingError: true,
          targetingLoading: false,
        },
      };
    }
    case appActions.FETCH_APPSTORE_ICON_SUCCESS: {
      let appStoreIcon = '';
      if (action.payload && Array.isArray(action.payload.results) && action.payload.results.length > 0) {
        const result = find(propEq('store_app_id', action.meta.storeAppId))(action.payload.results);
        if (result) {
          appStoreIcon = result.icon_url;
        }
      }
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          appStoreIconLoading: false,
          appStoreIconError: false,
          appStoreIcon,
        },
      };
    }
    case appActions.FETCH_APPSTORE_ICON_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          appStoreIconLoading: false,
          appStoreIconError: true,
        },
      };
    }
    case appActions.FETCH_AUTH_INFO_TOKEN_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          apiToken: action.payload.api_token,
          authenticationKey: action.payload.authentication_key,
          authenticationSignature: action.payload.authentication_signature,
          debugJwtToken: action.payload.debug_jwt_token,
          jwtSecret: action.payload.jwt_secret,
          oauthToken: action.payload.oauth_token,
        },
      };
    }
    case appActions.DELETE_APP_PENDING: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          deleteAppPending: true,
          deleteAppSuccess: false,
          deleteAppFailure: false,
          deleteAppFailureMessage: '',
        },
      };
    }
    case appActions.DELETE_APP_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          deleteAppPending: false,
          deleteAppSuccess: true,
          deleteAppFailure: false,
          deleteAppFailureMessage: '',
        },
      };
    }
    case appActions.DELETE_APP_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          deleteAppPending: false,
          deleteAppSuccess: false,
          deleteAppFailure: true,
          deleteAppFailureMessage: action.payload,
        },
      };
    }
    case appActions.UPDATE_APP_PENDING: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          updateAppPending: true,
          updateAppSuccess: false,
          updateAppFailure: false,
          updateAppFailureMessage: '',
        },
      };
    }
    case appActions.UPDATE_APP_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          fullyFetched: true,
          loading: false,
          lastUpdated: Date.now(),
          appMembers: action.payload.app_members || [],
          defaultLocale: action.payload.default_locale,
          displayName: action.payload.display_name,
          hideBranding: action.payload.hideBranding,
          iconUrl: action.payload.icon_url,
          id: action.payload.id || action.meta.appId,
          integratedFeatures: action.payload.integratedFeatures || action.payload.integrated_features,
          interactionReminderEmails: action.payload.interaction_reminder_emails,
          intlAppStorePref: action.payload.international_app_store_data,
          live: action.payload.live,
          localePolicy: action.payload.locale_policy,
          organization: action.payload.organization,
          platform: action.payload.platform,
          storeAppId: action.payload.store_app_id,
          supportDisplayEmail: action.payload.support_display_email,
          supportDisplayName: action.payload.support_display_name,
          supportImageUrl: action.payload.support_image_url,
          title: action.payload.title,
          websdkDebugFlag: action.payload.websdk_debug_flag,
          wentLiveOn: action.payload.went_live_on,
          updateAppPending: false,
          updateAppSuccess: true,
          updateAppFailure: false,
          updateAppFailureMessage: '',
        },
      };
    }
    case appActions.UPDATE_APP_FAILURE: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          updateAppPending: false,
          updateAppSuccess: false,
          updateAppFailure: true,
          updateAppFailureMessage: action.payload,
        },
      };
    }
    case AppDataActions.GET_SUGGESTED_ATTRIBUTES_PENDING: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          fetchAppAttributesPending: true,
          fetchAppAttributesSuccess: false,
          fetchAppAttributesFailure: false,
        },
      };
    }
    case AppDataActions.GET_SUGGESTED_ATTRIBUTES_SUCCESS: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          fetchAppAttributesPending: false,
          fetchAppAttributesSuccess: true,
          fetchAppAttributesFailure: false,
          appAttributes: action.payload.body,
        },
      };
    }
    case AppDataActions.GET_SUGGESTED_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          fetchAppAttributesPending: false,
          fetchAppAttributesSuccess: false,
          fetchAppAttributesFailure: true,
        },
      };
    }
    case AppDataActions.GET_ATTRIBUTES_PENDING: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          isCustomDataPending: true,
          isCustomDataFailure: false,
          personActiveCustomData: {},
          personArchievedCustomData: {},
          deviceActiveCustomData: {},
          deviceArchievedCustomData: {},
        },
      };
    }
    case AppDataActions.GET_ATTRIBUTES_SUCCESS: {
      const { personActiveCustomData, personArchievedCustomData, deviceActiveCustomData, deviceArchievedCustomData } =
        action.payload;

      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          isCustomDataPending: false,
          isCustomDataFailure: false,
          personActiveCustomData,
          personArchievedCustomData,
          deviceActiveCustomData,
          deviceArchievedCustomData,
        },
      };
    }
    case AppDataActions.GET_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          isCustomDataPending: false,
          isCustomDataFailure: true,
          personActiveCustomData: {},
          personArchievedCustomData: {},
          deviceActiveCustomData: {},
          deviceArchievedCustomData: {},
        },
      };
    }
    case AppDataActions.UPDATE_ATTRIBUTES_PENDING: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          personCustomDataLoadingKeys: state[action.payload.appId].personCustomDataLoadingKeys
            ? [...state[action.payload.appId].personCustomDataLoadingKeys, ...action.payload.personKeys]
            : action.payload.personKeys,
          deviceCustomDataLoadingKeys: state[action.payload.appId].deviceCustomDataLoadingKeys
            ? [...state[action.payload.appId].deviceCustomDataLoadingKeys, ...action.payload.deviceKeys]
            : action.payload.deviceKeys,
          isCustomDataFailure: false,
        },
      };
    }
    case AppDataActions.UPDATE_ATTRIBUTES_SUCCESS: {
      let newPersonActiveCustomData = { ...(state[action.payload.appId].personActiveCustomData || {}) };
      let newPersonArchievedCustomData = { ...(state[action.payload.appId].personArchievedCustomData || {}) };
      let newDeviceActiveCustomData = { ...(state[action.payload.appId].deviceActiveCustomData || {}) };
      let newDeviceArchievedCustomData = { ...(state[action.payload.appId].deviceArchievedCustomData || {}) };

      const removeKeyFromObj = (obj, key) =>
        Object.fromEntries(
          Object.keys(obj)
            .filter((el) => el !== key)
            .map((el) => [el, obj[el]]),
        );

      action.payload.body.person.forEach((el) => {
        if (el.is_visible) {
          newPersonArchievedCustomData = removeKeyFromObj(newPersonArchievedCustomData, el.key);
          newPersonActiveCustomData[el.key] = {
            ...newPersonActiveCustomData[el.key],
            is_visible: el.is_visible,
            description: el.description,
            type: el.type,
          };
          return;
        }
        newPersonActiveCustomData = removeKeyFromObj(newPersonActiveCustomData, el.key);
        newPersonArchievedCustomData[el.key] = {
          ...newPersonArchievedCustomData[el.key],
          is_visible: el.is_visible,
          description: el.description,
          type: el.type,
        };
      });

      action.payload.body.device.forEach((el) => {
        if (el.is_visible) {
          newDeviceArchievedCustomData = removeKeyFromObj(newDeviceArchievedCustomData, el.key);
          newDeviceActiveCustomData[el.key] = {
            ...newDeviceActiveCustomData[el.key],
            is_visible: el.is_visible,
            description: el.description,
            type: el.type,
          };
          return;
        }
        newDeviceActiveCustomData = removeKeyFromObj(newDeviceActiveCustomData, el.key);
        newDeviceArchievedCustomData[el.key] = {
          ...newDeviceArchievedCustomData[el.key],
          is_visible: el.is_visible,
          description: el.description,
          type: el.type,
        };
      });

      const personActiveCustomData = Object.fromEntries(
        Object.keys(newPersonActiveCustomData)
          .sort()
          .map((key) => [key, newPersonActiveCustomData[key]]),
      );
      const personArchievedCustomData = Object.fromEntries(
        Object.keys(newPersonArchievedCustomData)
          .sort()
          .map((key) => [key, newPersonArchievedCustomData[key]]),
      );
      const deviceActiveCustomData = Object.fromEntries(
        Object.keys(newDeviceActiveCustomData)
          .sort()
          .map((key) => [key, newDeviceActiveCustomData[key]]),
      );
      const deviceArchievedCustomData = Object.fromEntries(
        Object.keys(newDeviceArchievedCustomData)
          .sort()
          .map((key) => [key, newDeviceArchievedCustomData[key]]),
      );

      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          personCustomDataLoadingKeys: [],
          deviceCustomDataLoadingKeys: [],
          isCustomDataFailure: false,
          personActiveCustomData,
          personArchievedCustomData,
          deviceActiveCustomData,
          deviceArchievedCustomData,
        },
      };
    }
    case AppDataActions.UPDATE_ATTRIBUTES_FAILURE: {
      return {
        ...state,
        [action.payload.appId]: {
          ...state[action.payload.appId],
          personCustomDataLoadingKeys: [],
          deviceCustomDataLoadingKeys: [],
          isCustomDataFailure: true,
        },
      };
    }
    case AppDataActions.FETCH_SL_REACH_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          reachBySdkVersion: {
            ...state[action.meta.appId].reachBySdkVersion,
            [SdkVersion.SupportsSkipLogic]: action.payload.skip_logic_percentage,
          },
        },
      };
    }
    case AppDataActions.FETCH_RC_REACH_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          reachBySdkVersion: {
            ...state[action.meta.appId].reachBySdkVersion,
            [SdkVersion.SupportsRichContent]: action.payload.reach_percentage,
          },
        },
      };
    }
    case AppDataActions.FETCH_RICH_TEXT_REACH_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          reachBySdkVersion: {
            ...state[action.meta.appId].reachBySdkVersion,
            [SdkVersion.SupportsRichText]: action.payload.reach_percentage,
          },
        },
      };
    }
    case AppDataActions.FETCH_INITIATOR_REACH_SUCCESS: {
      return {
        ...state,
        [action.meta.appId]: {
          ...state[action.meta.appId],
          reachBySdkVersion: {
            ...state[action.meta.appId].reachBySdkVersion,
            [SdkVersion.SupportsInitiators]: action.payload.reach_percentage,
          },
        },
      };
    }
    case FETCH_SDK_VERSION_REACH_BY_APPS_SUCCESS: {
      return {
        ...state,
        ...Object.fromEntries(
          action.payload.map((appReach) => [
            appReach.app_id,
            {
              ...state[appReach.app_id],
              reachBySdkVersion: {
                ...state[appReach.app_id].reachBySdkVersion,
                [action.meta.sdkVersion]: appReach.reach_percentage,
              },
            },
          ]),
        ),
      };
    }
    default:
      return state;
  }
};
